.aboutSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 4rem !important;
  background-color: #1eb2a660;
  width: 30%;
  margin: auto;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.selectorText {
  font-size: 2rem;

  margin-right: 2rem;
  font-family: "Pacifico", cursive;
}
@media screen and (max-width: 1342px) {
  .aboutSelector {
    flex-direction: column;
    margin-top: 2rem;
    width: 90%;
  }
}
