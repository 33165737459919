.hero {
  margin-bottom: 4rem;
}
.hero #heading h3 {
  color: #fff;
  margin-top: 4rem;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
  color: #fff;
}
.hero p {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .hero {
    background-position: right;
  }
  .hero .row {
    width: 100%;
  }
}
